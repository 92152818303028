import React, { memo } from 'react';
import { Grid } from '@nextui-org/react';

import { Border, Container, Heading, TextWrapper } from '../../components';
import { useWindowSize } from '../../utils/getWindow';

import TeamDescription from './TeamDescription/TeamDescription';
import TeamImage from './TeamImage/TeamImage';


const Team = () => {
    const { width } = useWindowSize();
    const mobile = !!(width && width < 700);
    const tablet = !!(width && width > 700 && width < 950);

    return (
        <Container id="team" style={{ alignItems: 'flex-start' }}>
            <TextWrapper width={1200}>
                <Heading>Команда</Heading>
                <Border />
                <Grid.Container gap={2} justify="center">
                    <TeamDescription
                        name="Андрей Самочернов"
                        post="Дизайнер, архитектор"
                        description="Член союза дизайнеров Беларуси с 2015 года, участник международных выставок и семинаров по декоративным и строительным материалам"
                        order={mobile || tablet ? 1 : 0}
                    />
                    <TeamImage tablet={tablet} />
                    <TeamDescription
                        name="Екатерина Самочернова"
                        post="Дизайнер, декоратор"
                        description="Участник международных выставок и мастер-классов в сфере декоративного дизайна, материалов и текстиля"
                        order={mobile || tablet ? 1 : 0}
                    />
                </Grid.Container>
            </TextWrapper>
        </Container>
    );
};

export default memo(Team);