import React, { memo } from 'react';
import { Grid } from '@nextui-org/react';

import { Border, SubHeading } from '../../../components';

type TTeamDescription = {
    name: string;
    post: string;
    description: string;
    order?: number;
};

const TeamDescription: React.FC<TTeamDescription> = ({ name, post, description, order }) => {
    return (
        <Grid xs={12} sm={4} direction="column" justify="center" style={{ order }}>
            <SubHeading style={{ fontWeight: '600', marginBottom: '10px' }}>
                {name}
            </SubHeading>
            <SubHeading
                style={{
                    fontWeight: '400',
                    marginBottom: '15px',
                    fontSize: '18px',
                    color: '#707070',
                }}
            >
                {post}
            </SubHeading>
            <Border />
            <SubHeading
                style={{ marginBottom: '20px', fontWeight: '400', fontSize: '18px' }}
            >
                {description}
            </SubHeading>
        </Grid>
    );
};

export default memo(TeamDescription);