import React, { memo } from 'react';
import Image from 'next/image';
import { Grid } from '@nextui-org/react';
import styled from 'styled-components';

const ImageContainer = styled.div`
    width: 100%;
    box-shadow: 0px 0px 10px #353434, 20px 20px 10px #ffffff;
    border-radius: 20px;
    position: relative;
`;

const CustomImage = styled(Image)`
    width: 100% !important;
    position: relative !important;
    height: unset;
    border-radius: 15px;
    object-fit: cover;
`;

const TeamImage = ({ tablet }: { tablet: boolean }) => {
    return (
        <Grid xs={tablet ? 6 : 12} sm={4}>
            <ImageContainer>
                <CustomImage
                    src="/assets/main/6.webp"
                    alt="Team"
                    quality={10}
                    unoptimized={true}
                    fill
                />
            </ImageContainer>
        </Grid>
    );
};

export default memo(TeamImage);